:root {
  --light-blue: rgba(0, 146, 215, 1);
  --light-grey: rgba(29, 41, 54, 1);
}

.about-div p {
  font-size: 18px;
  margin: 0 10px;
}
.about-text {
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-bottom: 3rem;
}
.about-div h1,
.privacy h1 {
  text-align: center;
  color: var(--grey);
  filter: drop-shadow(1px 1px 1px var(--blue));
  font-size: 35px;
  margin: 30px 0;
}
.privacy h1 {
  font-weight: bold;
}
.about-div h2,
.privacy h2 {
  color: var(--blue);
  margin: 40px 0px 10px;
  font-size: 20px;
}
span.arrow {
  color: var(--grey);
  filter: drop-shadow(2px 3px var(--blue));
}
.privacy {
  width: 90%;
  margin: 10px auto;
}
.about-img {
  filter: drop-shadow(2px 2px 1px var(--blue));
}
.mission {
  background-color: var(--light-grey);
  color: var(--white);
  margin: 1.2rem;
  border-radius: 2rem;
  padding: 3rem;
  width: 60%;
  align-self: flex-end;
}
.mission h2 {
  margin: 0 0 1rem;
}
.mission,
.vision {
  box-shadow: 0.4rem 0.3rem 10px var(--grey);
}
.mission h2,
.vision h2 {
  color: var(--white);
  font-weight: bolder;
}
.mission-box {
  display: flex;
  justify-content: flex-end;
}
.mission li {
  max-width: 80%;
}
.vision {
  margin: 2rem;
  padding: 3rem;
  color: var(--white);
  background-color: var(--light-blue);
  width: 50%;
  border-radius: 2rem;
  margin-bottom: 1rem;
}
.vision h2 {
  margin: 0 0 1rem;
  padding: 0;
}
.image-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem 0;
}
.image-slider img {
  width: auto;
  height: 300px;
  object-fit: cover;
  object-position: center;
  border-radius: 1rem;
  filter: brightness(100%);
}
.image-slider img:hover {
  cursor: pointer;
  filter: brightness(80%);
}
.slider1,
.slider2 {
  margin: 2rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  overflow-x: hidden;
}
.image-slider h1 {
  background-color: var(--blue);
  color: white;
  width: fit-content;
  align-self: center;
  padding: 1rem;
  font-weight: light;
  border-radius: 0.8rem;
  box-shadow: 2px 2px 5px var(--grey);
}
@media (max-width: 1200px) {
  .vision,
  .mission {
    width: 80%;
  }
}
@media (max-width: 800px) {
  .vision,
  .mission {
    width: 90%;
  }
  .mission-box {
    display: flex;
    justify-content: center;
  }
  .image-slider img {
    max-width: 100%;
    height: auto;
  }
  .mission li {
    max-width: 100%;
  }
}

/* -------------------------------------------- */
/* why eworx  */
/* -------------------------------------------- */
.why-eworx,
.best-in {
  width: 100%;
}
.why-eworx > div h1 {
  text-align: left;
  color: var(--blue);
  text-transform: uppercase;
}
.why-eworx h3 {
  color: var(--blue);
  text-shadow: 0 0 0 0;
}
.why-eworx ul li {
  list-style-type: circle;
  font-size: 2rem;
}
.best-in div {
  text-align: center;
  padding: 10rem 2rem;
  border-radius: 2rem;
  box-shadow: 0rem 0rem 1rem var(--grey);
  color: var(--white);
  transition: all 0.25s ease-in-out;
}
.best-in div p {
  font-size: 22px;
}
.best-in div {
  margin: 0.5rem;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.best-in div:nth-of-type(even) {
  background-color: var(--grey);
}
.best-in div:nth-of-type(odd) {
  background-color: var(--blue);
}
.best-in div:hover {
  transform: scale(1.02);
  cursor: pointer;
}
.best-in {
  display: grid;
  grid-template-columns: repeat(3, calc(((100vw - 60px - 15rem) / 3)));
  gap: 2rem;
}
@media (max-width: 800px) {
  .best-in {
    display: grid;
    grid-template-columns: repeat(2, calc(((100vw - 60px - 15rem) / 2)));
    gap: 3rem;
  }
  .best-in div {
    padding: 4rem;
  }
}
@media (max-width: 550px) {
  .best-in {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
  }
}
