/*------------------------------------- remote staffing services------------------------------------------- */

.talentpool h1 {
  color: var(--grey);
  font-size: 30px;
  margin: 0px 10px;
  text-decoration: underline;
}
.staffing > h1,
.services-div h1,
#career h1 {
  color: var(--grey);
  text-shadow: 1px 1px 1px var(--blue);
  text-align: center;
  text-decoration: none;
}
.staffing-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0px 30px;
}
section.staffing-text {
  margin: 10px 0;
}
.header-img {
  width: 100%;
  height: 500px;
}
.talentpool p > ul li {
  color: var(--blue);
  font-weight: bold;
}
span.arrow {
  filter: drop-shadow(2px 3px var(--grey));
  color: var(--blue);
}
.staffing p > ul li:hover {
  color: var(--grey);
  cursor: default;
}
.benefits ul li {
  list-style-type: disc var(--blue);
  margin: 10px;
}
.benefits ul li b {
  font-size: 20px;
  color: var(--blue);
}
.staff-img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.talentpool {
  display: flex;
  justify-content: center;
  align-items: center;
}
.staff-img > img {
  filter: drop-shadow(4px 4px 4px var(--grey));
  height: 300px;
}
.benefits {
  background-color: var(--grey);
  color: white;
  border-radius: 10px;
  padding: 10px 30px;
  width: 92%;
  margin: auto;
}
.staffing .pic {
  /* height: auto; */
  width: 350px;
  filter: drop-shadow(8px 1px 3px var(--grey));
}

/* accounting styles */

.account-serivces {
  display: flex;
  align-items: center;
}

.acc-img {
  filter: drop-shadow(3px 3px 3px var(--blue));
}

.accounting b {
  color: var(--blue);
}

/* medical section */

section.medical h2,
.digital-marketing h2,
.web-dev h2,
.software-dev h2 {
  font-weight: bold;
  color: var(--blue);
  text-shadow: 0.5px 0.5px 1px var(--grey);
  font-size: 28px;
}

section.medical ul li {
  list-style-image: url("../assets/images/icons8-capsule-30.png");
  list-style-position: inside;
}

section.medical ul li b,
.digital-marketing b,
.web-dev b {
  font-size: 20px;
}

section.medical .flowchart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 10px 50px;
}
.medical-intro {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.med-img {
  width: 200px;
}
.benifits-div,
.commitment-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* digital marketing services  */

.marketing-icon {
  margin: 8px;
}
.digital-service,
.digital-why,
.marketing-services-detail ul > div,
.web-dev-services,
.soft-dev-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.digital-marketing ul li {
  list-style-image: url(../assets//images/icons8-digital-marketing-40.png);
  margin: 10px;
}
.digital-img > img {
  height: 400px;
  filter: drop-shadow(2px 2px 2px var(--blue));
}
ul.dig-mini-ul > li {
  list-style-image: none;
  list-style-type: disc;
  /* font-size: 2rem; */
}
h3 {
  font-weight: bold;
  color: var(--grey);
  text-shadow: 0.5px 0.5px var(--blue);
  font-size: 24px;
}
@media (max-width: 600px) {
  .digital-marketing {
    padding: 2rem 4rem;
  }
}
/* website development services  */

.web-dev ul li {
  list-style-position: outside;
  list-style: none;
}
.web-dev section {
  margin: 20px 0;
}
.webdev-img {
  display: flex;
  justify-content: center;
}
img.webdev {
  filter: drop-shadow(1px 0.5px 1px var(--grey));
}

/* software development services  */
.soft-dev-imgs {
  display: flex;
  justify-content: center;
}
.softdev1 {
  width: 350px;
  height: 350px;
}
.software-dev ul b {
  font-size: 21px;
}
.software-dev section {
  margin: 10px 0px;
  padding: 0 40px;
}
section.soft-dev-innovate {
  margin-bottom: 50px;
}
.soft-dev-img-section {
  display: flex;
  justify-content: center;
}
.sdlc {
  display: grid;
  justify-content: center;
  padding: 30px 0;
}
.grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgb(17, 146, 232, 0.8), var(--blue));
  box-shadow: 2px 2px 4px var(--blue);
  font-size: 16px;
  border-radius: 50%;
  padding: 10px;
  color: white;
  margin: 10px 20px;
}
.grid:hover {
  transform: scale(1.07);
  transition: all ease-in-out 0.25s;
  background-color: rgb(1, 80, 132);
}
.sdlc-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: min-content;
  width: 100%;
  padding: 50px 0;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8)),
    url(../assets/images//untitle5.png);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
