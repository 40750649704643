@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bricolage+Grotesque&family=Dancing+Script:wght@600&family=Inconsolata&family=Jost&family=Lato:wght@300;400&family=Montserrat:wght@300&family=Mukta&family=Open+Sans:wght@300&family=Poppins:wght@200;300&family=Preahvihear&family=Roboto+Slab&family=Young+Serif&display=swap");

/* universal styles */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

@media (max-width: 1400px) {
  html {
    font-size: 58.5%;
  }
}

@media (max-width: 900px) {
  html {
    font-size: 55.5%;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 50.5%;
  }
}

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  line-height: 1.6;
  max-width: 100vw;
}

*:focus {
  outline-color: var(--blue);
}

::selection {
  background-color: var(--blue);
  color: var(--antiflashwhite);
}

:root {
  /* colors  */

  --white: #fff;
  --seasalt: #f7f7f7;
  --antiflashwhite: #e7ecef;
  --blue: #0092d7;
  --grey: #1d2936;

  /* text sizes  */

  --small: 14px;
  --medium: 18px;
  --large: 20px;
  --larger: 35px;
  --extra-large: 40px;
}

:link {
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
  font-size: 18px;
}

a {
  font-size: 18px;
}

.reveal {
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;
}

.reveal.active {
  opacity: 1;
  transform: translateY(0px);
}

.whatsapp-div {
  z-index: 5;
  position: fixed;
  bottom: 10px;
  right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 1rem;
  font-size: 2rem;
}

.whatsapp-text {
  background-color: var(--antiflashwhite);
  box-shadow: 0px 0px 5px var(--grey);
  padding: 1rem 2rem;
  border-radius: 12px;
  position: relative;
  display: block;
}

.whatsapp_btn {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 0px 3px black);
  transition: all 0.3s ease-in-out;
}

.whatsapp_btn:hover {
  transform: scale(1.07);
}

@media (max-width: 600px) {
  .whatsapp-text {
    display: none;
  }

  .whatsapp_btn {
    width: 40px;
    height: 40px;
  }
}

section {
  padding: 5rem 8rem;
}

h1 {
  margin: 10px 0px;
  font-weight: bold;
}

h2 {
  margin: 20px 0px;
  font-size: var(--larger);
  font-weight: bolder;
}

h3 {
  margin: 10px 0px;
}

p {
  font-size: var(--medium);
}

ol,
ul {
  padding-left: 2rem;
}

.blue {
  color: var(--blue);
  font-weight: bold;
}

.grey {
  color: var(--grey);
}

.bk-grey {
  background-color: var(--blue);
  color: var(--white);
}

div.page {
  margin: 20px 0 30px;
}

/*--------------------------------------- Styling of Navbar -------------------------------------*/
nav {
  background-color: transparent;
}

.logo {
  height: 100px;
}

nav ul li {
  margin: 5px 25px;
}

ul li {
  list-style: none;
  margin: 5px 5px;
}

span.arrow {
  width: 5px;
}

.nav-link {
  font-size: 19px;
  text-decoration: none;
  color: var(--grey);
  font-weight: 400;
  transition: all 0.1s ease-in-out;
  position: relative;
  display: inline;
}

.nav-link::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  transform: translateX(0);
  background-color: var(--blue);
}

.nav-link:hover::before {
  transform: translateX(1);
  width: 100%;
  transition: all 0.35s ease-in-out;
}

.nav-link:hover {
  color: var(--blue);
}

.nav-link-services:hover {
  background-color: var(--blue);
  color: var(--antiflashwhite);
}

.btn-contact {
  background-color: var(--blue);
  color: var(--antiflashwhite);
  padding: 10px 20px;
  filter: drop-shadow(2px 2px 4px var(--blue));
  border-radius: 5px;
  margin-right: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.btn-contact:hover {
  color: var(--white);
}

.btn-contact::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 0;
  top: 0;
  left: -10%;
  z-index: -1;
  background-color: var(--grey);
  transform: skewX(35deg);
  transition: all 0.5s ease-in-out;
}

.btn-contact:hover::before {
  width: 100%;
}

.navbar-toggler-icon {
  width: 3rem;
  height: 3rem;
}

/*------------------------------------------ styilng of home page --------------------------------------------*/

/*-------------------------------------------- hero-container -----------------------------------------------*/

.hero-container {
  height: calc(100vh - 70px);
  width: 100%;
  background-image: url("../assets/images/lycs-architecture-U2BI3GMnSSE-unsplash.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: start;
  align-items: center;
}

.overlay {
  position: absolute;
  inset: 0;
  width: inherit;
  cursor: auto;
  height: 100%;
  background: rgba(0, 146, 215, 0.4);
  z-index: 1;
  /* filter: blur(10px); */
}

.intro-div {
  z-index: 2;
  color: var(--white);
}

.intro-text {
  text-align: justify;
  width: 500px;
  background: rgba(29, 41, 54);
  padding: 2rem;
  border-radius: 24px;
  line-height: 1.8;
}

@media (max-width: 1300px) {
  .hero-container {
    justify-content: center;
  }

  .intro-text {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .intro-text {
    width: 80%;
  }
}

@media (max-width: 780px) {
  .intro-text {
    width: 100%;
  }
}

.intro-div > h1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-shadow: 3px 3px 5px var(--grey);
  font-size: 5rem;
  color: var(--antiflashwhite);
}

.intro-div > h1 span:last-child {
  color: transparent;
  text-shadow: none;
  letter-spacing: 0.2rem;
  position: relative;
}

.intro-div > h1 span:last-child::before {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  text-shadow: 3px 3px 5px var(--grey);
  color: var(--antiflashwhite);
  border-right: 3px solid var(--blue);
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }

  70%,
  90% {
    width: 100%;
  }
}

/*---------------------------------------------- home services section --------------------------------------*/

.home-services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.service {
  position: relative;
  background-color: var(--blue);
  color: var(--antiflashwhite);
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 2px 2px 7px var(--grey);
  cursor: pointer;
}

.services > h2 {
  text-align: center;
  color: var(--grey);
}

.services h3 {
  color: var(--antiflashwhite);
  font-size: var(--large);
  font-weight: bolder;
  text-shadow: 1px 1px 2px var(--grey);
}

.service:hover {
  background-color: var(--grey);
  box-shadow: 2px 2px 10px var(--grey);
  color: var(--antiflashwhite);
  transition: all 0.5s ease-in-out;
}

.title-icon {
  display: flex;
  justify-content: space-between;
}

.home-service {
  height: 60px;
  filter: drop-shadow(1px 1px 1px white);
}

.home-service-icons {
  display: flex;
  justify-content: space-between;
}

/*------------------------------------------------ home about section ---------------------------------------*/
section.about {
  color: var(--antiflashwhite);
  background-color: var(--grey);
}

.home-about {
  padding: 20px 40px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-about div > p {
  width: 80%;
  margin: 20px 0px;
}

a.btn-about {
  font-size: var(--medium);
  color: var(--blue);
}

.globe {
  filter: drop-shadow(2px 2px 3px var(--blue));
}

/*-------------------------------------------- home clients section  ----------------------------------------*/

.brandslogo {
  width: 150px;
  height: 100px;
}

.sbrandslogo {
  width: 150px;
  height: 50px;
}

.logos {
  padding: 60px 0;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  max-width: 1500px;
  margin: auto;
}

.logos::before,
.logos::after {
  position: absolute;
  width: 100px;
  height: auto;
  content: "";
  top: 0;
  z-index: 2;
}

.logos::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  right: 0;
}

.logos::before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  left: 0;
}

.logos-slide {
  display: inline-block;
  animation: 15s slide infinite linear;
}

.logos-slide > img {
  filter: brightness(100%);
  margin: 0 4rem;
}

.logos-slide > img:hover {
  filter: grayscale(100%);
}

@media (max-width: 500px) {
  .logos-slide > img {
    margin: 0 2rem;
  }

  .logos::before,
  .logos::after {
    width: 50px;
  }

  .logos {
    padding: 30px 0;
  }
}

/*----------------------------------------- animations  ------------------------------------------------------*/

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0.5;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    transform: translateY(0);
  }
}

/* testimonials style */
/* General Styling */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
}

/* Slider Styling */
.slick-slide {
  padding: 10px;
}

.slick-dots li.slick-active button:before {
  color: #000;
  /* Active dot color */
}

.item {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px var(--grey);
  transition: all 0.3s ease;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  max-height: max-content;
}
.item:hover {
  box-shadow: 0px 0px 30px var(--grey) !important;
}

.about-div p {
  font-size: 16px !important;
  margin: 0px !important;
}
.rating h3 {
  font-size: 19px;
}
.item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}
span.read-more {
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}
.item img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border-radius: 50%;
}

/* Rating Section */
.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.stars {
  display: flex;
}

.stars img {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

img#award {
  height: 400px;
  border-radius: 12px;
}
section#award {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section#ceo_msg p {
  font-size: 20px !important;
  text-align: center;
  margin: 0 70px !important;
}
@media (max-width: 1000px) {
  section#ceo_msg p {
    font-size: 16px !important;
    margin: 0px !important;
  }
  img#award {
    height: auto;
    width: 100%;
    min-width: 300px;
    border-radius: 12px;
    filter: brightness(200%);
  }
}
img#ceo {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  filter: drop-shadow(0 0 10px var(--grey));
}
section#ceo_msg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
@media (max-width: 786px) {
  section#ceo_msg {
    flex-direction: column;
  }
  img#ceo {
    width: 300px;
    height: 300px;
  }
}
