/* media queries for medium sized devices : max-width = 1440px */

@media only screen and (max-width: 1440px) {
  body {
    max-width: 100vw !important;
  }
}

/* media queries for medium sized devices : max-width = 1330px */

@media only screen and (max-width: 1330px) {
  .clients .home-clients {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
  .hero-img {
    width: 60%;
  }
  nav {
    padding: 0;
  }
  .get-in-touch {
    display: none;
  }

  a:-webkit-any-link {
    font-size: 16px;
  }
  .logo {
    height: 60px;
  }
  p {
    font-size: 16px;
  }
  .brandslogo {
    width: 120px;
    height: 70px;
  }
  .clients > .home-clients {
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

/* media queries for medium sized devices : max-width = 1250px */

@media only screen and (min-width: 1250px) {
  .sdlc {
    display: grid;
    grid-template-columns: repeat(6, 0.1fr);
    justify-content: center;
  }
}
/* media queries for medium sized devices : max-width = 1250px */

@media only screen and (max-width: 1250px) {
  .account-serivces {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .benifit-img {
    display: none;
  }
  .sdlc {
    display: grid;
    grid-template-columns: repeat(3, 0.1fr);
  }
}
/* media queries for medium sized devices : max-width = 1100px */

@media only screen and (max-width: 1100px) {
  .clients .home-clients {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 30px;
  }
  .brandslogo {
    width: 80px;
    height: 100px;
  }
  .sbrandslogo {
    width: 80px;
    height: 50px;
  }
  .staffing .pic4,
  .staffing .pic5,
  .staffing .pic6 {
    display: none;
  }
  .staffing .pic {
    width: 380px;
  }
  .commit-img > img {
    display: none;
  }
  .about-text,
  .grid {
    display: flex;
    flex-direction: column;
  }
  .btn-contact {
    display: none;
  }
}

/* media queries for medium sized devices : max-width = 990px */

@media only screen and (max-width: 990px) {
  .digital-service,
  .digital-why,
  .marketing-services-detail ul > div,
  .soft-dev-flex,
  .web-dev-services {
    display: flex;
    flex-direction: column;
  }
  .intro-div > h1 {
    font-size: 40px;
  }
  .nav-link-services {
    font-size: 14px;
    color: var(--grey);
  }
  .hero-container {
    gap: 30px;
    display: flex;
    animation: FadeIn 0.75s ease-in-out forwards;
    flex-direction: column;
  }
  .home-services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .globe {
    display: none;
  }
  .home-about {
    padding: 10px;
    min-height: 50vh;
  }
  .home-about div > p {
    width: 100%;
    margin: 10px 0px;
  }
  .staffing-text {
    display: flex;
    flex-direction: column;
  }
  .talentpool {
    display: flex;
    flex-direction: column;
  }
}

/* media queries for medium sized devices : max-width = 780px */

@media only screen and (max-width: 780px) {
  .brandslogo {
    width: 100px;
    height: 100px;
  }
  .sbrandslogo {
    width: 100px;
    height: 40px;
  }
}

/* media queries for medium sized devices : max-width = 700px */

@media only screen and (max-width: 700px) {
  .staffing .pic6 {
    display: none;
  }
  .medical-intro {
    display: flex;
    flex-direction: column;
  }
  .webdev-img > img {
    width: 400px;
    height: 400px;
  }
  .sdlc {
    display: grid;
    grid-template-columns: repeat(2, 0.1fr);
    justify-content: center;
  }
}
/* media queries for medium sized devices : max-width = 680px */

@media only screen and (max-width: 680px) {
  .home-services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  #webdev3 {
    height: 400px;
    width: 400px;
  }
  .home-about {
    min-height: 33vh;
  }
  nav > div.hamburger div.ham-item {
    width: 2.5rem;
    height: 5px;
  }
  .footer-text,
  .footer {
    display: flex;
    flex-wrap: wrap;
  }
  .company-info > p {
    width: 100%;
  }

  .hero-img {
    width: 100%;
    height: 30vh;
  }
  .webpage-links > ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
  }
  .footer ul a li {
    font-size: 14px;
  }
  .rights {
    font-size: 12px;
  }
  .brandslogo {
    width: 120px;
    height: 100px;
    /* margin: 0px 10px 0px 0px; */
  }
  .clients > .home-clients {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  img.flowchart {
    width: 450px;
  }
}

/* media queries for medium sized devices : min-width = 720px */

@media only screen and (min-width: 700px) {
  .webdev-img > img {
    width: 700px;
    height: 700px;
  }
 
}

/* media queries for medium sized devices : max-width = 530px */

@media only screen and (max-width: 530px) {
  .clients .home-clients {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 30px;
  }
}
/* media queries for medium sized devices : min-width = 480px */

@media only screen and (min-width: 480px) {
  .account-section p {
    font-size: 20px;
  }
  .softdev2 {
    width: 450px;
    height: 450px;
  }
  .about-div {
    color: var(--grey);
    margin: 30px 60px;
  }
  td {
    padding: 12px 20px;
  }
  .staffing > h1,
  .services-div h1 {
    font-size: 40px;
  }
  .dm-3 {
    width: 400px;
    height: 400px;
  }
  .grid {
    height: 170px;
    width: 170px;
  }
  .staffing p {
    margin: 20px 0px;
    font-size: 20px;
  }
  .benefits {
    box-shadow: 5px 5px 4px var(--blue);
  }
}
/* media queries for medium sized devices : max-width = 480px */

@media only screen and (max-width: 480px) {
  img.flowchart {
    width: 350px;
  }
  #webdev3 {
    width: 300px;
    height: 300px;
  }
  .qatar-icon {
    margin-top: 20px;
  }
  .benefits {
    box-shadow: 2px 2px 2px var(--blue);
  }
  .brandslogo {
    width: 100px;
    height: 80px;
    /* margin: 0px 10px 0px 0px; */
  }
  .intro-div > h1 {
    font-size: 36px;
  }
  .staffing p {
    margin: 20px 0px;
    font-size: 16px;
  }
  td {
    padding: 8px 8px;
  }
  ol,
  ul {
    padding-left: 1rem;
  }
  section {
    padding: 1rem 2rem;
  }
  .grid {
    height: 150px;
    width: 150px;
  }
  .softdev2 {
    width: 350px;
    height: 350px;
  }
  .webdev-img > img {
    width: 320px;
    height: 320px;
  }
  .dm-3 {
    width: 300px;
    height: 300px;
  }
  .why-img > img {
    height: 300px;
    width: 300px;
  }
  .staffing > h1,
  .services-div h1 {
    font-size: 30px;
  }
  .about-div {
    color: var(--grey);
    margin: 30px 10px;
  }
  .vission {
    display: flex;
    flex-direction: column;
  }
  .acc-img {
    width: 300px;
  }

  .staffing .pic {
    width: 310px;
  }
  p {
    font-size: 15px;
  }
  .about-img {
    width: 315px;
  }
  .home-about div > p {
    font-size: 14px;
  }
  /* .about-img {
    display: none;
  } */
  .home-about div > h2 {
    font-size: 22px;
  }
  nav > div.hamburger div.ham-item {
    width: 2.5rem;
    height: 5px;
    margin: 5px;
    border-radius: 2px;
    background-color: var(--blue);
  }
  .account-section p {
    font-size: 17px;
  }
}
/* media queries for medium sized devices : max-width = 350px */

@media only screen and (max-width: 360px) {
  td {
    padding: 8px 2px;
  }
}
/* media queries for medium sized devices : max-width = 300px */

@media only screen and (max-width: 300px) {
  nav > div.hamburger div.ham-item {
    width: 2rem;
    height: 4px;
    margin: 3px;
    border-radius: 2px;
    background-color: var(--blue);
  }
  .clients > .home-clients {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .staff-img > img {
    height: 260px;
  }
  .staffing .pic {
    width: 230px;
  }
  img.flowchart {
    width: 270px;
  }
}

/*----------------------------------------- animations  ------------------------------------------------------*/
@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
