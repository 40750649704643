* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  /* colors  */

  --white: #fff;
  --seasalt: #f7f7f7;
  --antiflashwhite: #e7ecef;
  --blue: #0092d7;
  --grey: #1d2936;

  /* text sizes  */

  --small: 14px;
  --medium: 18px;
  --large: 20px;
  --larger: 35px;
  --extra-large: 40px;
}
.footer {
  padding: 20px 50px 10px;
  background-color: var(--grey);
  color: var(--antiflashwhite);
  min-height: 40vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.footer-text {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 50px;
}
.footer p {
  font-size: 14px;
}
.company-info > p {
  width: 70%;
}

.webpage-links > ul {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
}
.footer .footer-text .social-links{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}
.rights{
    position: relative;
    bottom: 10px;
    margin-top: 20px ;
    font-size: 15px;
}
.icons-link{
    height: 30px;
    margin: 0px 5px ;
}
.footer ul li{
  color: var(--antiflashwhite);
}
.footer ul li:hover{
  color: var(--blue);
}
a{
  color: var(--antiflashwhite);
}
a:hover{
  color: var(--blue);
}