/* ------------------------------------ */
/* clients-------- */
/* ------------------------------------ */

.clients {
  max-width: 92%;
  margin: auto;
}
.clients-intro > p {
  margin: 15px 0px;
  font-size: 18px;
}

table,
tr,
td {
  border: 1px solid var(--blue);
}
.market-client > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients h2 {
  font-weight: bold;
  color: var(--blue);
  text-shadow: 0.5px 0.5px 1px var(--grey);
  font-size: 25px;
}
.clients {
  margin-bottom: 30px;
}

.clients img {
  filter: brightness(100%);
}
.clients img:hover {
  filter: grayscale(100%);
}

/* ------------------------------------ */
/* contact-------- */
/* ------------------------------------ */
#contact {
  margin: auto;
  max-width: 1280px;
}
.form {
  width: 800px;
}
#form {
  box-shadow: 0px 0px 10px var(--grey);
  border-radius: 20px;
  padding: 50px;
  margin: 30px 20px;
}
label {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
input:focus,
textarea:focus {
  outline-color: var(--blue);
}
input,
textarea {
  width: 100%;
  padding: 3px;
}
textarea {
  height: 150px;
}
#submit-btn {
  font-size: 16px;
  padding: 5px 20px;
  border: 2px solid var(--blue);
  border-radius: 8px;
  box-shadow: 0 0 3px 1px var(--grey);
  background-color: var(--blue);
  color: var(--white);
  width: 100px;
}
#submit-btn:hover {
  background-color: var(--grey);
  border: 2px solid var(--grey);
}
span.red {
  color: brown;
}
.contact-flex-main {
  gap: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-info {
  margin: 20px 5px;
}
.offices {
  gap: 0px 20px;
  margin: 10px;
}
#contact h1,
form h1 {
  color: var(--grey);
  text-shadow: 1px 1px var(--blue);
  font-size: 30px;
}
.contact-info h2 {
  font-size: 28px;
  text-shadow: 1px 1px 1px var(--grey);
  color: var(--blue);
}
.office .office-data {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
}
iframe.map {
  width: 600px;
  height: 450px;
}
.offices {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}
.international-offices {
  margin: 5rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem 10rem;
  flex-wrap: wrap;
}
.contact-info span {
  font-size: 16px;
}
.contact-img {
  width: 100%;
  height: 300px;
}
.contact_logo {
  width: 23px;
}
@media (max-width: 1360px) {
  .offices {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
  }

  .international-offices {
    flex-direction: column;
    gap: 0px;
  }
  .office.uk,
  .office.canada {
    padding: 0 10px;
    margin: 0;
  }
  iframe.map {
    height: 350px;
  }
}
@media (max-width: 990px) {
  .contact-flex-main {
    display: flex;
    gap: 20px 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  label {
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .form {
    width: 700px;
  }
}
@media (max-width: 730px) {
  .form {
    width: 550px;
  }
  iframe.map {
    height: 350px;
    width: 570px;
  }
  .offices {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .office {
    width: 100%;
    padding: 20px 10px;
  }
}
@media (max-width: 600px) {
  .form {
    width: 420px;
  }
  #form {
    padding: 30px;
  }
  iframe.map {
    height: 350px;
    width: 420px;
  }
}
@media (max-width: 440px) {
  .form {
    width: 350px;
  }
  #form {
    padding: 20px;
  }
  iframe.map {
    height: 350px;
    width: 320px;
  }
}

.accordion-button,
.accordion-body {
  font-size: 2rem;
} /* ------------------------------------ */
/* careers-------- */
/* ------------------------------------ */

.career-intro {
  min-height: 17vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#career h1 {
  font-size: 38px;
}
#career h2 {
  font-weight: bold;
  color: var(--white);
  text-shadow: 0.5px 0.5px 1px var(--grey);
  font-size: 32px;
  text-align: center;
}
.career_tagline,
.recruitment p {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 21px;
  word-spacing: 1px;
}
.career-pic {
  height: 450px;
  width: 650px;
}
.main-career {
  margin: 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 100px;
  padding: 50px 20px;
}
.career-para {
  font-size: 20px;
  width: 600px;
}
.recruitment,
.job-openings {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hiring-process {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
  margin: 30px;
}
.recruitment p {
  font-size: 18px;
}
.career-flex-item {
  background-color: var(--blue);
  color: var(--white);
  padding: 20px 30px 50px;
  border-radius: 14px;
  box-shadow: 5px 5px 10px black;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.career-flex-item:hover {
  background-color: var(--grey);
  color: var(--white);
  cursor: pointer;
  transform: scale(1.05);
}
.career-flex-item p {
  width: 300px;
  text-align: center;
}
.hiring-process > .career-flex-item img {
  height: 50px;
}
.career-flex-item div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.recruitment > div > p {
  width: 800px;
}
.flex-job-openings {
  margin: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 50px;
}
.job {
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0px 0px 8px var(--grey);
}
.job:hover {
  cursor: pointer;
}
.job > p {
  text-align: justify;
  margin: 15px 0;
}
.job .job-title {
  text-align: center;
  font-size: 30px;
}
.apply {
  margin: 50px 0px;
}
.apply p {
  font-size: 20px;
  text-align: center;
}
@media (max-width: 1270px) {
  .hiring-process {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin: 30px 10px;
  }
  .main-career {
    margin: 30px 0px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 70px;
    padding: 20px;
  }
  img.career-pic {
    width: 630px;
    height: 430px;
  }

  .career-intro {
    min-height: 10vh;
  }
}

@media (max-width: 1190px) {
  .hiring-process {
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  .flex-job-openings {
    flex-direction: column;
  }
  img.career-pic {
    width: 530px;
    height: auto;
  }

  .career-para {
    font-size: 18px;
    width: 520px;
  }
}

@media (max-width: 1010px) {
  .main-career {
    margin: 35px 0px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }
  img.career-pic {
    height: 400px;
  }
  .recruitment > div > p {
    width: 550px;
  }
}
@media (max-width: 600px) {
  .career_tagline {
    font-size: 18px;
    line-height: 1.5;
    word-spacing: normal;
  }
  .recruitment > div > p {
    width: 470px;
  }
  img.career-pic {
    width: 100%;
    height: 350px;
  }
  .job {
    padding: 15px;
  }
  .job ul {
    padding: 0px;
    margin: 0;
  }
  .job b {
    font-size: 16px;
  }
  .flex-job-openings {
    margin: 20px;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  img.career-pic {
    height: 300px;
  }
  .recruitment > div > p {
    width: 320px;
  }
  .career_tagline,
  .career-para,
  .recruitment p {
    font-size: 16px;
  }
  .career-flex-item {
    width: 325px;
    height: auto;
  }
  #career h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .career-intro {
    margin: 10px 20px;
  }
  .job-openings {
    margin: 0px;
  }
  .apply {
    margin: 40px 0px;
  }
  .apply p {
    font-size: 18px;
    text-align: center;
  }
}
@media (max-width: 440px) {
  img.career-pic {
    height: 280px;
  }
}
